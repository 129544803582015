<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="$emit('save-result', payload)"
        @update:show="$emit('update:show', $event)">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Candidate Type</label>
                        <select class="form-control" v-model="type">
                            <option value="presidential">Presidential</option>
                            <option value="parliamentary">Parliamentary</option>
                        </select>
                    </div>
                </div>
            </div>

			<div class="row">
				<div class="col-xs-12 col-6">
                    <div class="form-group">
                        <label for="constituency">Constituency</label>

                        <div class="select2-wrapper">
                            <select
                                id="constituency"
                                ref="constituency"
                                class="select-2 form-control"
                                data-placeholder="Select A Constituency">
                                    <option></option>

                                    <option v-for="(constituency, index) in constituencies"
                                        :value="constituency.id"
                                        :key="constituency.id">{{ constituency.name }}</option>
                            </select>
                        </div>
                    </div>
				</div>

				<div class="col-xs-12 col-6">
                    <div class="form-group">
                        <label for="electoralArea">Electoral Area</label>

                        <div class="select2-wrapper">
                            <select
                                id="electoralArea"
                                ref="electoralArea"
                                class="select-2 form-control"
                                data-placeholder="Select an Electoral Area">
                                    <option></option>

                                    <option v-for="(area, index) in electoralAreas"
                                        :value="area.id"
                                        :key="area.id">{{ area.name }}</option>
                            </select>
                        </div>
                    </div>
				</div>
            </div>

			<div class="row">
                <div class="col-xs-12 col-6">
                    <div class="form-group">
                        <label for="branch">Branches</label>

                        <div class="select2-wrapper">
                            <select
                                id="branch"
                                ref="branch"
                                class="select-2 form-control"
                                data-placeholder="Select A Branch">
                                <option></option>
                                <option v-for="(branch, index) in branches"
                                    :value="branch.id"
                                    :key="branch.id">
                                    {{ branch.name }}
                                </option>
                            </select>
                        </div>
                    </div>
				</div>

				<div class="col-6">
                    <div class="form-group">
                        <label for="candidate">Candidate</label>

                        <div class="select2-wrapper">
                            <select
                                id="candidate"
                                ref="candidate"
                                class="select-2 form-control"
                                data-placeholder="Select A Candidate">
                                    <option></option>

                                    <option v-for="(candidate, index) in candidates"
                                        :value="candidate.id"
                                        :key="candidate.id">{{ candidate.name }}</option>
                            </select>
                        </div>
                    </div>
				</div>
			</div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group" :class="voteClass">
                        <label for="vote">Vote</label>

                        <input type="text"
                            name="vote"
                            v-model="payload.vote"
                            class="form-control"
                            v-validate="rules.vote"
                            placeholder="Total votes for candidate"/>

                        <error-label :message="errors.first('vote')" />
                    </div>
                </div>
            </div>
    </modal>
</template>

<script>
    import Config from '@/../config';
    import { mapGetters, mapActions } from 'vuex';

	export default {
		props: {
            show: {
                type: Boolean,
                default: false
            },

            result: {
                type: Object,
                default: {}
            },

            electionId: {
                type: Number,
                default: null
            }
        },

		data() {
			return {
                candidates: [],
                type: 'presidential',
                payload: {
                    vote: '',
                    branch_id: '',
                    candidate_id: '',
                },

                constituency_id: '',
                electoral_area_id: '',

                rules: {
                    vote: 'required|integer|min_value:0',
                }
			};
        },

        computed: {
            ...mapGetters({
                branches: 'Branches/getAll',
                constituencies: 'Constituencies/getAll',
                electoralAreas: 'ElectoralAreas/getAll'
            }),

            /**
             * Check if the current operation is an update.
             *
             * @return {Boolean}
             */
            isUpdate() {
                return Object.keys(this.result).length > 0;
            },

            /**
             * Compute the has danger class for the vote field.
             *
             * @return {Object}
             */
            voteClass() {
                return { 'has-danger': this.errors.has('vote') };
            },

            /**
             * Title for the modal depending on the current operation.
             *
             * @return {String}
             */
            title() {
                return this.isUpdate ? 'Edit Result' : 'Add Result';
            }
        },

		watch: {
            /**
             * Toggle the id property depending on the save operation. Update or Save.
             *
             * @param {Boolean} value
             * @return {Undefined}
             */
			show(value) {
                this.resetModal();

                if (this.isUpdate) {
                    this.getResultDetails();
                    this.$set(this.payload, 'id', this.result.id);
                } else {
                    this.$delete(this.payload, 'id');
                }
            },

            type() {
                if (this.payload.branch_id) {
                    this.getCandidates();
                }
            }
		},

		methods: {
            ...mapActions({
                loadConstituencies: 'Constituencies/all',
                loadBranches: 'Branches/all',
                loadElectoralAreas: 'ElectoralAreas/all',
                loadCandidates: 'Elections/loadNonCollatedCandidates',
                loadAllCandidates: 'Candidates/all'
            }),

            /**
             * Load constituencies
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getConstituencies(query = {}) {
                this.loadConstituencies(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Load branches
             *
             * @return {Undefined}
             */
            getBranches() {
                if (!this.electoral_area_id) {
                    return false;
                }

                this.loadBranches({
                    electoral_area_id: this.electoral_area_id,
                    hide_unknown: true,
                })
                .then(response => {
                    if (this.payload.branch_id) {
                        $(this.$refs.branch).val(this.payload.branch_id).trigger('change');
                    }
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            getElectoralAreas() {
                if (!this.constituency_id) {
                    return false;
                }

                this.loadElectoralAreas({
                        constituency_id: this.constituency_id
                    })
                    .then(response => {
                        if (this.electoral_area_id) {
                            $(this.$refs.electoralArea).val(this.electoral_area_id).trigger('change');
                        }
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            getCandidates() {
                let method;

                if (!this.isUpdate) {
                    method = this.loadCandidates({
                        branch_id: this.payload.branch_id,
                        election_id: this.electionId,
                        type: this.type
                    });
                } else {
                    method = this.loadAllCandidates({
                        type: this.type,
                        constituency_id: this.constituency_id
                    });
                }

                method.then(response => {
                    this.candidates = response;

                    this.$nextTick(() => {
                        if (this.payload.candidate_id) {
                            $(this.$refs.candidate).val(this.payload.candidate_id).trigger('change');
                        }
                    });
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Set branch value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setBranch(event) {
                this.payload.branch_id = event.target.value;

                if (this.payload.branch_id) {
                    this.getCandidates();
                }
            },

            /**
             * Set candidate value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setCandidate(event) {
                this.payload.candidate_id = event.target.value;
            },

            setConstituency(event) {
                this.constituency_id = event.target.value;
                this.getElectoralAreas();
            },

            setElectoralArea(event) {
                this.electoral_area_id = event.target.value;
                this.getBranches();
            },

            /**
             * Get the details of the result to be updated.
             *
             * @return {Undefined}
             */
            getResultDetails() {
                for (let key of Object.keys(this.payload)) {
                    if (typeof this.result[key] !== 'undefined') {
                        this.$set(this.payload, key, this.result[key]);
                    }
                }

                this.electoral_area_id = this.result.branch.electoral_area_id;
                this.type = this.result.candidate.type;
                this.payload.candidate_id = this.result.candidate_id;

                $(this.$refs.constituency).val(this.result.branch.electoral_area.constituency_id).trigger('change');
            },

            /**
             * Reset the modal.
             *
             * @return {Undefined}
             */
            resetModal() {
                for (let key of Object.keys(this.payload)) {
                    this.$set(this.payload, key, '');
                }

                $(this.$refs.branch).val('').trigger('change');
                $(this.$refs.candidate).val('').trigger('change');
                $(this.$refs.constituency).val('').trigger('change');

                this.$nextTick(() => this.errors.clear());
            },

            /**
             * Register event listeners for select 2.
             *
             * @return {Undefined}
             */
            registerEventListeners() {
                $(this.$refs.branch).on('change', this.setBranch);
                $(this.$refs.candidate).on('change', this.setCandidate);
                $(this.$refs.constituency).on('change', this.setConstituency);
                $(this.$refs.electoralArea).on('change', this.setElectoralArea);
            }
        },

        mounted() {
            this.registerEventListeners();
            this.getConstituencies();
        }
	}
</script>

<style scoped>
</style>
