<template>
    <div class="card grid-margin">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-account-group"></i>
                Candidates
            </h1>

            <p class="card-description">The candidates of this election</p>

            <div class="row">
                <div class="col-12">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-xs-12 col-6">
                                <div class="form-group">
                                    <label for="candidate">Candidate</label>

                                    <div class="select2-wrapper">
                                        <select
                                            id="candidate"
                                            ref="candidate"
                                            class="select-2 form-control"
                                            data-placeholder="Select A Candidate">
                                                <option></option>

                                                <option v-for="(candidate, index) in candidates"
                                                    :value="candidate.id"
                                                    :key="index">{{ candidate.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-6">
                                <button type="button" class="btn btn-primary mt-3 float-right" @click="$emit('add-candidate', newCandidate)">
                                    <i class="mdi mdi-plus"></i>
                                    Add Candidate
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="type">Filter By Type</label>

                                    <select
                                        id="type"
                                        ref="type"
                                        class="select-2 form-control"
                                        data-placeholder="Select a candidate type">
                                        <option value="">Select a candidate type</option>
                                        <option value="parliamentary">Parliamentary</option>
                                        <option value="presidential">Presidential</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="constituency_id">Filter By Constituency</label>
                                    <select
                                        id="constituency_id"
                                        ref="constituency"
                                        class="select-2 form-control"
                                        data-placeholder="Select A Constituency">
                                            <option value="">Select A Constituency</option>

                                            <option v-for="(constituency, index) in constituencies"
                                                :value="constituency.id"
                                                :key="index">{{ constituency.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 table-wrapper">
                                <template v-if="electionCandidates && electionCandidates.length">
                                    <div class="table-responsive">
                                        <table class="table dataTable table-striped no-footer" role="grid">
                                            <thead>
                                                <tr role="row">
                                                    <th></th>
                                                    <th class="sorting">Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr role="row" v-for="(candidate, index) in electionCandidates" :key="index">
                                                    <td class="py-1"><img :src="photoSrc(candidate.photo)"></td>
                                                    <td>{{ candidate.name }}</td>
                                                    <td>
                                                        <button class="btn btn-danger btn-action" @click="$emit('remove-candidate', candidate.id)" data-toggle="popover" data-content="Remove Candidate">
                                                            <i class="mdi mdi-trash-can"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">No candidate has been added to this election.</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: ['election'],

        data() {
            return {
                newCandidate: '',
                electionCandidates: null,
                type: '',
                constituency_id: ''
            }
        },

        computed: {
            ...mapGetters({
                candidates: 'Candidates/getAll',
                constituencies: 'Constituencies/getAll'
            })
        },

        watch: {
            election: {
                deep: true,
                handler(value) {
                    this.getCandidates();
                    this.getElectionCandidates();
                }
            },

            type(value) {
                this.getElectionCandidates();
            },

            constituency_id() {
                this.getElectionCandidates();
            }
        },

        methods: {
			...mapActions({
                load: 'Candidates/all',
                loadConstituencies: 'Constituencies/all',
                loadElectionCandidates: 'Candidates/loadElectionCandidates'
            }),

            /**
             * Get the photo src.
             *
             * @param {String} photo
             * @return {String}
             */
            photoSrc(photo) {
                return photo ? photo : this.placeholder;
            },

            /**
             * Get all candidates from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getCandidates() {
                if (!this.election.id) {
                    return false;
                }

                this.load({ for_election: this.election.id })
                    .then(() => {
                        this.newCandidate = '';
                        $(this.$refs.candidate).select2('destroy');
                        $(this.$refs.candidate).val('').select2();
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Set candidate value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setCandidate(event) {
                this.newCandidate = event.target.value;
            },

            setConstituency(event) {
                this.constituency_id = event.target.value;
            },

            setType(event) {
                this.type = event.target.value;
            },

            /**
             * Register event listeners for select 2.
             *
             * @return {Undefined}
             */
            registerEventListeners() {
                $(this.$refs.candidate).on('change', this.setCandidate);
                $(this.$refs.type).on('change', this.setType);
                $(this.$refs.constituency).on('change', this.setConstituency);
            },

            /**
             * Get constituencies into state
             *
             * @return {Undefined}
             */
            getConstituencies() {
                return this.loadConstituencies({})
                    .then(() => {
                        return Promise.resolve();
                    })
                    .catch(errors =>  {
                        this.notify(this.buildErrors(errors), 'error');

                        return Promise.reject();
                    });
            },

            getElectionCandidates() {
                let query = {
                    type: this.type,
                    paginate: false,
                    in_election: this.election.id,
                    constituency_id: this.constituency_id
                };

                return this.loadElectionCandidates(query)
                    .then(response => {
                        this.electionCandidates = response;
                        return Promise.resolve();
                    })
                    .catch(errors =>  {
                        this.notify(this.buildErrors(errors), 'error');

                        return Promise.reject();
                    });
            }
        },

        mounted() {
            this.getCandidates();
            this.registerEventListeners();
            this.getConstituencies();
            $(this.$refs.type).val('presidential').change();
        }
    }
</script>

<style scoped>
    .table-wrapper {
        height: 301px;
        max-height: 301px;
        overflow: auto;
    }
</style>
