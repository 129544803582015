<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="$emit('save-ballot', payload)"
        @update:show="$emit('update:show', $event)">
			<div class="row">
				<div class="col-12">
                    <div class="form-group">
                        <label for="constituency">Constituency</label>

                        <div class="select2-wrapper">
                            <select
                                id="constituency"
                                ref="constituency"
                                class="select-2 form-control"
                                data-placeholder="Select A Constituency">
                                    <option></option>

                                    <option v-for="(constituency, index) in constituencies"
                                        :value="constituency.id"
                                        :key="constituency.id">{{ constituency.name }}</option>
                            </select>
                        </div>
                    </div>
				</div>
            </div>

			<div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="electoralArea">Electoral Area</label>

                        <div class="select2-wrapper">
                            <select
                                id="electoralArea"
                                ref="electoralArea"
                                class="select-2 form-control"
                                data-placeholder="Select an Electoral Area">
                                    <option></option>

                                    <option v-for="(area, index) in electoralAreas"
                                        :value="area.id"
                                        :key="area.id">{{ area.name }}</option>
                            </select>
                        </div>
                    </div>
				</div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="branch">Branches</label>

                        <div class="select2-wrapper">
                            <select
                                id="branch"
                                ref="branch"
                                class="select-2 form-control"
                                data-placeholder="Select A Branch">
                                <option></option>
                                <option v-for="(branch, index) in branches"
                                    :value="branch.id"
                                    :key="branch.id">
                                    {{ branch.name }}
                                </option>
                            </select>
                        </div>
                    </div>
				</div>
			</div>

            <div class="row">
                <div class="col-6">
                    <div class="form-group" :class="presidentialClass">
                        <label for="presidential">Presidential Ballots</label>

                        <input type="text"
                            name="presidential_count"
                            v-model="payload.presidential_count"
                            class="form-control"
                            v-validate="rules.presidential_count"
                            placeholder="Rejected presidential ballots"
                            autocomplete="off" />

                        <error-label v-show="errors.has('presidential_count')" :message="errors.first('presidential_count')" />
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group" :class="parliamentaryClass">
                        <label for="presidential">Parliamentary Ballots</label>

                        <input type="text"
                            name="parliamentary_count"
                            v-model="payload.parliamentary_count"
                            class="form-control"
                            v-validate="rules.parliamentary_count"
                            placeholder="Rejected parliamentary ballots"
                            autocomplete="off" />

                        <error-label v-show="errors.has('parliamentary_count')" :message="errors.first('parliamentary_count')" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Voter turnout</label>

                        <input type="text"
                            name="voter_turnout"
                            v-model="payload.voter_turnout"
                            class="form-control"
                            v-validate="rules.voter_turnout"
                            placeholder="Voter turnout"
                            autocomplete="off" />

                        <error-label v-show="errors.has('voter_turnout')" :message="errors.first('voter_turnout')" />
                    </div>
                </div>
            </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        ballot: {
            type: Object,
            default: null
        },

        electionId: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            payload: {
                election_id: '',
                branch_id: '',
                presidential_count: 0,
                parliamentary_count: 0,
                voter_turnout: 0
            },

            constituency_id: '',
            electoral_area_id: '',

            rules: {
                presidential_count: 'required|integer|min_value:0',
                parliamentary_count: 'required|integer|min_value:0',
                voter_turnout: 'required|integer|min_value:0'
            }
        }
    },

    computed: {
        ...mapGetters({
            branches: 'Branches/getAll',
            constituencies: 'Constituencies/getAll',
            electoralAreas: 'ElectoralAreas/getAll'
        }),

        /**
         * Compute the has danger class for the presidential.
         *
         * @return {Object}
         */
        presidentialClass() {
            return { 'has-danger': this.errors.has('presidential_count') };
        },

        /**
         * Compute the has danger class for the parliamentary rejected ballot field
         *
         * @return {Object}
         */
        parliamentaryClass() {
            return { 'has-danger': this.errors.has('parliamentary_count') };
        },

        /**
         * Check if the current operation is an update.
         *
         * @return {Boolean}
         */
        isUpdate() {
            return this.ballot && Object.keys(this.ballot).length > 0;
        },

        /**
         * Title for the modal depending on the current operation.
         *
         * @return {String}
         */
        title() {
            return this.isUpdate ? 'Edit Polling Summary' : 'Add Polling Summary';
        }
    },

    watch: {
        /**
         * Toggle the id property depending on the save operation. Update or Save.
         *
         * @param {Boolean} value
         * @return {Undefined}
         */
        show(value) {
            this.resetModal();

            if (this.isUpdate) {
                this.setupEditForm();
                this.$set(this.payload, 'id', this.ballot.id);
            } else {
                this.$delete(this.payload, 'id');
            }
        }
    },

    methods: {
        ...mapActions({
            loadBranches: 'Branches/all',
            loadConstituencies: 'Constituencies/all',
            loadElectoralAreas: 'ElectoralAreas/all'
        }),

        /**
         * Load constituencies
         *
         * @param {Object} query
         * @return {Undefined}
         */
        getConstituencies(query = {}) {
            this.loadConstituencies(query)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Load branches
         *
         * @return {Undefined}
         */
        getBranches() {
            if (!this.electoral_area_id) {
                return false;
            }

            this.loadBranches({
                electoral_area_id: this.electoral_area_id,
                hide_unknown: true,
            })
            .then(response => {
                if (this.payload.branch_id) {
                    $(this.$refs.branch).val(this.payload.branch_id).trigger('change');
                }
            })
            .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        getElectoralAreas() {
            if (!this.constituency_id) {
                return false;
            }

            this.loadElectoralAreas({
                    constituency_id: this.constituency_id
                })
                .then(response => {
                    if (this.electoral_area_id) {
                        $(this.$refs.electoralArea).val(this.electoral_area_id).trigger('change');
                    }
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
        },

        /**
         * Set branch value when the select changes.
         *
         * @param {Object} event
         * @return {Undefined}
         */
        setBranch(event) {
            this.payload.branch_id = event.target.value;
        },

        setConstituency(event) {
            this.constituency_id = event.target.value;
            this.getElectoralAreas();
        },

        setElectoralArea(event) {
            this.electoral_area_id = event.target.value;
            this.getBranches();
        },

        /**
         * Get the details of the result to be updated.
         *
         * @return {Undefined}
         */
        setupEditForm() {
            for (let key of Object.keys(this.payload)) {
                if (typeof this.ballot[key] !== 'undefined') {
                    this.$set(this.payload, key, this.ballot[key]);
                }
            }

            this.electoral_area_id = this.ballot.branch.electoral_area_id;

            this.$nextTick(() => {
                $(this.$refs.constituency).val(this.ballot.branch.electoral_area.constituency_id).trigger('change');
            });

        },

        /**
         * Register event listeners for select 2.
         *
         * @return {Undefined}
         */
        registerEventListeners() {
            $(this.$refs.branch).on('change', this.setBranch);
            $(this.$refs.constituency).on('change', this.setConstituency);
            $(this.$refs.electoralArea).on('change', this.setElectoralArea);
        },

        /**
         * Reset the modal.
         *
         * @return {Undefined}
         */
        resetModal() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
            }

            $(this.$refs.branch).val('').trigger('change');
            $(this.$refs.constituency).val('').trigger('change');

            this.$nextTick(() => this.errors.clear());
        }
    },

    mounted() {
        this.getConstituencies();
        this.registerEventListeners();
    }
}
</script>