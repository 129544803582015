<template>
    <div class="card grid-margin">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-chart-areaspline"></i>
                Election Results
            </h1>

            <p class="card-description">The reported results about this election</p>

            <div class="row">
                <div class="col-12">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-primary" @click="addElectionResult">
                                    <i class="mdi mdi-plus"></i>
                                    Add Election Results
                                </button>

                                <button type="button" class="btn btn-warning report-btn ml-3" @click="navigateToReports">
                                    <i class="mdi mdi-chart-bar"></i>
                                    View Reports
                                </button>
                            </div>

                            <div class="col-6">
                                <toolbar
                                    @show="val => params.per_page = val"
                                    @search="val => params.keyword = val" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="results.length">
                                    <div class="table-responsive">
                                        <table class="table dataTable table-striped no-footer" role="grid">
                                            <thead>
                                                <tr role="row">
                                                    <th class="sorting">Branch Code</th>
                                                    <th class="sorting">Branch Name</th>
                                                    <th class="sorting">Candidate</th>
                                                    <th class="sorting">Votes</th>
                                                    <th class="sorting">Status</th>
                                                    <th class="sorting">Reported By</th>
                                                    <th class="sorting">Created At</th>
                                                    <th class="sorting">Updated At</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr role="row" v-for="(result, index) in results" :key="index">
                                                    <td>{{ result.branch && result.branch.code ? result.branch.code : 'N/A' }}</td>
                                                    <td>{{ result.branch && result.branch.name ? result.branch.name : 'N/A' }}</td>
                                                    <td>{{ result.candidate && result.candidate.name ? result.candidate.name : 'N/A' }}</td>
                                                    <td>{{ result.vote }} </td>
                                                    <td>{{ result.status && result.status.name ? result.status.name : 'N/A' }}</td>
                                                    <td>{{ result.user && result.user.name ? result.user.name : 'N/A' }}</td>
                                                    <td>{{ result.created_at | formattedDateTime }}</td>
                                                    <td>{{ result.updated_at | formattedDateTime }}</td>
                                                    <td>
                                                        <button
                                                            v-if="result.status.name.toLowerCase() === 'unconfirmed'"
                                                            class="btn btn-success btn-action mr-3 float-right"
                                                            @click="confirmElectionResult(result.id)"
                                                            data-toggle="popover"
                                                            data-content="Confirm Result">
                                                            <i class="mdi mdi-check-decagram"></i>
                                                        </button>
                                                    </td>

                                                    <td>
                                                        <button class="btn btn-warning btn-action mr-3" @click="editElectionResult(result)" data-toggle="popover" data-content="Edit Result">
                                                            <i class="mdi mdi-pencil"></i>
                                                        </button>

                                                        <button class="btn btn-danger btn-action" @click="deleteElectionResult(result.id)" data-toggle="popover" data-content="Delete Result">
                                                            <i class="mdi mdi-trash-can"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <app-pagination
                                        v-if="pageDetails && pageDetails.total"
                                        :pageDetails="pageDetails"
                                        @navigate="getElectionResults($event)" />
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">No result has been reported yet</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<modal
			:show="showModal"
            :result="modalResult"
            :electionId="election.id"
            @save-result="saveElectionResult"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import Modal from './ResultsModal';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        props: ['election'],

        components: {
            Modal
        },

        data() {
            return {
                showModal: false,
                modalResult: {},
                params: {
                    paginate: true,
                    per_page: null,
                    keyword: null
                }
            };
        },

        computed: {
            ...mapGetters({
                results: 'ElectionResults/getAll',
                pageDetails: 'ElectionResults/getPageDetails'
            })
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.getElectionResults();
                }
            },
            election: {
                deep: true,
                handler() {
                    this.getElectionResults();
                }
            }
        },

		methods: {
			...mapActions({
                load: 'ElectionResults/all',
                store: 'ElectionResults/store',
                update: 'ElectionResults/update',
                delete: 'ElectionResults/delete',
                confirm: 'ElectionResults/confirm',
            }),

            /**
             * Get the photo src.
             *
             * @param {String} photo
             * @return {String}
             */
            photoSrc(photo) {
                return photo ? photo : this.placeholder;
            },

            /**
             * Open the add election modal.
             *
             * @return {Undefined}
             */
            addElectionResult() {
                this.showModal = true;
                this.modalResult = {};
            },

            /**
             * Open the edit election modal.
             *
             * @param {Object} election
             * @return {Undefined}
             */
            editElectionResult(election) {
                this.showModal = true;
                this.modalResult = { ...election };
            },

            /**
             * Get all election results from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getElectionResults(query = {}) {
                for (let param of Object.keys(this.params)) {
                    if (this.params[param]) {
                        query[param] = this.params[param];
                    }
                }

                this.load({
                    ...query,
                    election_id: this.election.id
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));

                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getElectionResults, 60000);
            },

            /**
             * Save the election result in the backend.
             *
             * @param {Object} result
             * @return {Undefined}
             */
            saveElectionResult(result) {
                let keyword = 'added';
                let savingMethod = this.store;
                let payload = {
                    data: {
                        ...result,
                        election_id: this.election.id
                    }
                };

                if (result.id !== 'undefined' && result.id) {
                    keyword = 'updated';
                    payload.id = result.id;
                    savingMethod = this.update;
                }

                savingMethod(payload)
                    .then(response => {
                        this.notify(`The election result was ${keyword} successfully.`);
                        this.getElectionResults();
                        this.showModal = false;
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

			/**
			 * Delete the specified election result.
             * Request for a deletion confirmation.
			 *
			 * @param  {Number} id
			 * @return {Undefined}
			 */
			deleteElectionResult(id) {
				Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this election result again!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(id)
							.then(() => {
								this.notify('The election result was deleted successfully.');
								this.getElectionResults();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Go to reports page
             */
            navigateToReports() {
                this.$router.push({ name: 'election_reports', params: { election_id: this.election.id }})
            },

            /**
             * Confirm the specified election result.
             *
             * @param {Number} id
             * @return {Undefined}
             */
            confirmElectionResult(id) {
				Swal({
					title: "Are you sure?",
					text: "Once confirmed, we deem this election result as valid and verified!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Confirm',
                    confirmButtonClass: 'bg-success',
                    cancelButtonText: 'No - Cancel'
				})
				.then(response => {
					if (response.value) {
						this.confirm(id)
							.then(() => {
								this.notify('The election result was confirmed successfully.');
								this.getElectionResults();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            }
        },

        mounted() {
            this.getElectionResults();
        },

        beforeDestroy() {
            clearTimeout(this.timeout);
        }
    }
</script>

<style scoped>
    .report-btn {
        color: #ffffff;
    }
</style>
