<template>
    <div class="card grid-margin">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-library"></i>
                Election Details
            </h1>

            <p class="card-description">The details of this election</p>

            <div class="row">
                <div class="col-12">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <button type="button" class="btn btn-primary" @click="$emit('edit-election')">
                                    <i class="mdi mdi-pencil"></i>
                                    Edit
                                </button>

                                <button type="button" class="btn btn-danger ml-3" @click="$emit('delete-election')">
                                    <i class="mdi mdi-trash-can"></i>
                                    Delete
                                </button>

                                <button type="button" class="btn btn-success float-right" v-if="!isActive" @click="$emit('make-election-active')">
                                    <i class="mdi mdi-check-decagram"></i>
                                    Make Active
                                </button>

                                <button type="button" class="btn btn-default float-right" v-else  @click="$emit('make-election-inactive')">
                                    <i class="mdi mdi-decagram"></i>
                                    Make Inactive
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="election && Object.keys(election).length">
                                    <div class="table-responsive">
                                        <table class="table dataTable table-striped no-footer" role="grid">
                                            <tbody>
                                                <tr role="row">
                                                    <td>Name</td>
                                                    <td>{{ election.name }}</td>
                                                </tr>

                                                <tr>
                                                    <td>No. of Candidates</td>
                                                    <td>{{ election.candidates.length }} </td>
                                                </tr>

                                                <tr>
                                                    <td>Date of Election</td>
                                                    <td>{{ election.date | formattedDateTime }}</td>
                                                </tr>

                                                <tr>
                                                    <td>Status</td>
                                                    <td>{{ election.status.name }}</td>
                                                </tr>

                                                <tr>
                                                    <td>Created By</td>
                                                    <td>{{ election.user.name }}</td>
                                                </tr>

                                                <tr>
                                                    <td>Created At</td>
                                                    <td>{{ election.created_at | formattedDateTime }}</td>
                                                </tr>

                                                <tr>
                                                    <td>Updated At</td>
                                                    <td>{{ election.updated_at | formattedDateTime }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">No election has been loaded.</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['election'],

        computed: {
            isActive() {
                if (this.election && this.election.status) {
                    return this.election.status.name.toLowerCase() === 'active';
                }

                return false;
            }
        }
    }
</script>