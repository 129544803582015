<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="$emit('save-election', payload)"
        @update:show="$emit('update:show', $event)">
			<div class="row justify-content-center">
				<div class="col-xs-12 col-10">
                    <div class="form-group" :class="nameClass">
                        <label for="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            v-model="payload.name"
                            class="form-control"
                            v-validate="rules.name"
                            placeholder="Election's Name">

                        <error-label :message="errors.first('name')" />
                    </div>

                    <div class="form-group">
                        <label for="date">Date</label>
                        <input
                            id="date"
                            type="text"
                            ref="date"
                            class="form-control"
                            placeholder="Date of Election">
                    </div>

                    <div class="form-group" v-show="!this.isUpdate">
                        <input type="checkbox" id="add_candidates" v-model="payload.add_all_candidates">
                        <label for="add_candidates" style="margin-left: 5px"> Add all candidates to election</label>
                    </div>
				</div>
			</div>
    </modal>
</template>

<script>
    import moment from 'moment';

	export default {
		props: {
            show: {
                type: Boolean,
                default: false
            },

            election: {
                type: Object,
                default: {}
            }
        },

		data() {
			return {
                payload: {
                    name: '',
                    date: '',
                    add_all_candidates: true
                },

                rules: {
                    name: 'required|min:2',
                    date: 'required|date'
                }
			};
        },

        computed: {
            /**
             * Check if the current operation is an update.
             *
             * @return {Boolean}
             */
            isUpdate() {
                return Object.keys(this.election).length > 0;
            },

            /**
             * Compute the has danger class for the name field.
             *
             * @return {Object}
             */
            nameClass() {
                return { 'has-danger': this.errors.has('name') };
            },

            /**
             * Compute the has danger class for the date field.
             *
             * @return {Object}
             */
            dateClass() {
                return { 'has-danger': this.errors.has('date') };
            },

            /**
             * Title for the modal depending on the current operation.
             *
             * @return {String}
             */
            title() {
                return this.isUpdate ? 'Edit Election' : 'Add Election';
            }
        },

		watch: {
            /**
             * Toggle the id property depending on the save operation. Update or Save.
             *
             * @param {Boolean} value
             * @return {Undefined}
             */
			show(value) {
                this.resetModal();

                if (this.isUpdate) {
                    this.getElectionDetails();
                    this.$set(this.payload, 'id', this.election.id);
                } else {
                    this.$delete(this.payload, 'id');
                }

                if (value) {
                    this.initDatePicker();
                } else {
                    $(this.$refs.date).data('daterangepicker').remove();
                }
			}
		},

		methods: {
            /**
             * Get the details of the election to be updated.
             *
             * @return {Undefined}
             */
            getElectionDetails() {
                this.payload.name = this.election.name;
                this.payload.date = this.election.date;

                this.$nextTick(() => {
                    $(this.$refs.date).val(moment(this.payload.date).format("dddd, Do MMM YYYY - hh:MM A"))//.trigger('apply.daterangepicker');
                });
            },

            /**
             * Reset the modal.
             *
             * @return {Undefined}
             */
            resetModal() {
                this.payload.name = '';
                this.payload.date = '';
                this.$nextTick(() => this.errors.clear());
            },

            /**
             * Initialize the date picker.
             *
             * @return {Undefined}
             */
            initDatePicker() {
                $(this.$refs.date).daterangepicker({
                    minDate: moment(),
                    autoUpdateInput: true,
                    singleDatePicker: true,
                    showCustomRangeLabel: false,

                    locale: {
                        "format": "dddd, Do MMM YYYY",
                    }
                }, this.setDate);

                this.setDate(moment());
            },

            /**
             * Set the date value when the date picker changes.
             *
             * @param {Date} date
             * @return {Undefined}
             */
            setDate(date) {
                this.payload.date = date.format('YYYY-MM-DD');
            }
        },

        mounted() {
            this.initDatePicker();
        }
	}
</script>

<style scoped>
</style>
