<template>
    <div class="card grid-margin">
        <div class="card-body">
            <h1 class="card-title">
                <i class="mdi mdi-chart-areaspline"></i>
                Polling Summary
            </h1>

            <p class="card-description">Details about rejected ballots and voter turnout</p>

            <div class="row">
                <div class="col-12">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row">
                            <div class="col-6">
                                <button type="button" class="btn btn-primary" @click="showModal = true">
                                    <i class="mdi mdi-plus"></i>
                                    Add Summary
                                </button>
                            </div>

                            <div class="col-6">
                                <toolbar
                                    @show="val => params.per_page = val"
                                    @search="val => params.keyword = val" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <template v-if="ballots.length">
                                    <div class="table-responsive">
                                        <table class="table dataTable table-striped no-footer" role="grid">
                                            <thead>
                                                <tr role="row">
                                                    <th>Branch</th>
                                                    <th>Rejected Pres.</th>
                                                    <th>Rejected Parl.</th>
                                                    <th>Total Votes Pres.</th>
                                                    <th>Total Votes Parl.</th>
                                                    <th>Voter Turnout</th>
                                                    <th>Expected Turnout</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr role="row" v-for="(ballot, index) in ballots" :key="index" :class="{'table-danger': isOverVoting(ballot)}">
                                                    <td>{{ ballot.branch.name }}</td>
                                                    <td>{{ ballot.presidential_count }}</td>
                                                    <td>{{ ballot.parliamentary_count }}</td>
                                                    <td>{{ ballot.total_presidential_votes }}</td>
                                                    <td>{{ ballot.total_parliamentary_votes }}</td>
                                                    <td>{{ ballot.voter_turnout }}</td>
                                                    <td>{{ ballot.branch.registered_voters }}</td>
                                                    <td>
                                                        <button class="btn btn-warning btn-action mr-3"
                                                            data-toggle="popover"
                                                            data-content="Edit Rejected Ballots"
                                                            @click="editBallot(ballot)">
                                                            <i class="mdi mdi-pencil"></i>
                                                        </button>

                                                        <button class="btn btn-danger btn-action"
                                                            data-toggle="popover"
                                                            data-content="Delete Rejected Ballots"
                                                            @click="deleteBallot(ballot.id)">
                                                            <i class="mdi mdi-trash-can"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <app-pagination
                                        v-if="pageDetails && pageDetails.total"
                                        :pageDetails="pageDetails"
                                        @navigate="getRejectedBallots($event)" />
                                </template>

                                <template v-else>
                                    <p class="lead mt-5 mb-5 text-center">No polling data entered yet</p>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal
			:show="showModal"
            :electionId="electionId"
            :ballot="modalBallot"
            @save-ballot="saveRejectedBallot"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Modal from './SummaryModal';

    export default {
        components: {
            Modal
        },

        props: {
            show: {
                type: Boolean,
                default: false
            },

            electionId: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                showModal: false,
                params: {
                    paginate: true,
                    per_page: null,
                    keyword: ''
                },
                modalBallot: null
            }
        },

        computed: {
            ...mapGetters({
                ballots: 'PollingSummaries/getAll',
                pageDetails: 'PollingSummaries/getPageDetails'
            })
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.getRejectedBallots();
                }
            }
        },

        methods: {
            ...mapActions({
                load: 'PollingSummaries/all',
                store: 'PollingSummaries/store',
                update: 'PollingSummaries/update',
                delete: 'PollingSummaries/delete'
            }),

            saveRejectedBallot(ballot) {
                let savingMethod = this.store;
                let payload = {
                    data: {
                        ...ballot,
                        election_id: this.electionId
                    }
                };

                if (ballot.id) {
                    payload.id = ballot.id;
                    savingMethod = this.update;
                }

                savingMethod(payload)
                    .then(response => {
                        this.notify(`The polling summary has been saved.`);
                        this.getRejectedBallots();
                        this.showModal = false;
                        this.modalResult = {};
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Get all rejected ballots from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getRejectedBallots(query = {}) {
                for (let param of Object.keys(this.params)) {
                    if (this.params[param]) {
                        query[param] = this.params[param];
                    }
                }

                this.load({
                    ...query,
                    election_id: this.electionId
                })
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));

                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getBallots, 60000);
            },

            editBallot(ballot) {
                this.showModal = true;
                this.modalBallot = { ...ballot };
            },

            deleteBallot(ballotId) {
                Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this records again!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(ballotId)
							.then(() => {
								this.notify('The rejected ballots record was deleted successfully.');
								this.getRejectedBallots();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            isOverVoting(ballot) {
                const turnout = ballot.voter_turnout;
                const registered_voters = ballot.branch.registered_voters;

                return (turnout != null && turnout > registered_voters)
                    || ballot.total_presidential_votes > registered_voters
                    || ballot.total_parliamentary_votes > registered_voters
                    || (turnout != null && ballot.total_presidential_votes > turnout)
                    || (turnout != null && ballot.total_parliamentary_votes > turnout);
            }
        },

        mounted() {
            this.getRejectedBallots();
        }
    }
</script>
