<template>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col-6">
                    <election-details
                        :election="election"
                        @edit-election="showModal = true"
                        @delete-election="deleteElection"
                        @make-election-active="activateElection"
                        @make-election-inactive="deactivateElection" />
                </div>

                <div class="col-6">
                    <election-candidates
                        :election="election"
                        @add-candidate="addElectionCandidate"
                        @remove-candidate="removeElectionCandidate" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <election-results :election="election" />
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <polling-summaries :electionId="election ? election.id : null" />
                </div>
            </div>
        </div>

		<modal
			:show="showModal"
            :election="election"
            @save-election="saveElection"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import Modal from '../Modal';
    import { mapActions, mapGetters } from 'vuex';
    import ElectionDetails from './Details';
    import ElectionResults from './Results';
    import ElectionCandidates from './Candidates';
    import PollingSummaries from './PollingSummary';

    export default {
        props: ['id'],

        components: {
            Modal,
            ElectionDetails,
            ElectionResults,
            PollingSummaries,
            ElectionCandidates
        },

        data() {
            return {
                election: {},
                showModal: false,

                resultsParams: {
                    paginate: true,
                    per_page: null,
                    keyword: null
                }
            };
        },

        computed: {
            ...mapGetters({
                results: 'Elections/getAll',
                pageDetails: 'Elections/getPageDetails'
            })
        },

        watch: {
            resultsParams: {
                deep: true,
                handler() {
                    this.getElection();
                }
            }
        },

		methods: {
			...mapActions({
                show: 'Elections/show',
                update: 'Elections/update',
                delete: 'Elections/delete',
                activate: 'Elections/activate',
                deactivate: 'Elections/deactivate',
                addCandidate: 'Elections/addCandidate',
                removeCandidate: 'Elections/removeCandidate',
            }),

            /**
             * Get details of the election from the server.
             *
             * @return {Undefined}
             */
            getElection() {
                this.show(this.id)
                    .then(response => this.election = { ...response })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Save the election in the backend.
             *
             * @param {Object} election
             * @return {Undefined}
             */
            saveElection(election) {
                const payload = {
                    id: election.id,
                    data: election
                };

                this.update(payload)
                    .then(response => {
                        this.notify(`The election was updated successfully.`);
                        this.getElection();
                        this.showModal = false;
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

			/**
			 * Delete this election.
             * Request for a deletion confirmation.
			 *
			 * @return {Undefined}
			 */
			deleteElection() {
				Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this election and its data!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(this.election.id)
							.then(() => {
								this.$router.push({ name: 'elections' });
								this.notify('The election was deleted successfully.');
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Add candidate from election.
             *
             * @param {Number} candidate_id
             * @return {Undefined}
             */
            addElectionCandidate(candidate_id) {
                if (!candidate_id) {
                    this.notify('Select a candidate to be added.', 'error');
                    return false;
                }

                const payload = {
                    candidate: { candidate_id },
                    election_id: this.election.id
                };

                this.addCandidate(payload)
                    .then(response => {
                        this.notify('The candidate was added to this election successfully.');
                        this.getElection();
                    })
					.catch(errors => this.notify(this.buildErrors(errors), 'error'))
            },

            /**
             * Remove candidate from election.
             *
             * @param {Number} candidate_id
             * @return {Undefined}
             */
            removeElectionCandidate(candidate_id) {
                const payload = {
                    candidate: { candidate_id },
                    election_id: this.election.id
                };

				Swal({
					title: "Are you sure?",
					text: "Once removed, you will not be able to recover this candidate!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Remove it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Remove'
				})
				.then(response => {
					if (response.value) {
                        this.removeCandidate(payload)
                            .then(response => {
                                this.notify('The candidate was removed from this election successfully.');
                                this.getElection();
                            })
                            .catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Activate this election.
             *
             * @return {Undefined}
             */
            activateElection() {
				Swal({
					title: "Are you sure?",
					text: "Once activated, previous active elections will be inactive and data collections can be made only for this election!",
					type: "info",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Activate it.',
                    confirmButtonClass: 'bg-primary',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.activate(this.election.id)
							.then(() => {
                                this.getElection();
                                this.notify('The election was activated successfully.')
                            })
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Deactivate this election.
             *
             * @return {Undefined}
             */
            deactivateElection() {
				Swal({
					title: "Are you sure?",
					text: "Once deactivated, data collections cannot be made for this election!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Activate it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.deactivate(this.election.id)
							.then(() => {
                                this.getElection();
                                this.notify('The election was deactivated successfully.')
                            })
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            }
        },

        mounted() {
            this.getElection();
        }
    }
</script>

<style lang="scss" scoped>
</style>


